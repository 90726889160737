import admin from './admin'
import mobile from './mobile'
import request  from './request'
// import {getQueryString} from '@/util'

// console.log(getQueryString('from'));

// console.log('api env:',process.env, 'process.env');
//授予STS的临时管理权限, 用于文件上传
const getStsAssume = () => {
  return request.get('/api/third/getStsAssume')
}

const getUserToken = (code:string) => {
  return request.get('/api/user/token?code='+code)
}

const getUserAccessToken = (params) => {
  return request.get(`/api/feishu/signatureByUserAccessToken?code=${params.code}&url=${params.url}`)
}
//获取当前用户姓名和工号
const getUserInfo = () => {
    return request.get('/api/user/userinfo/current')
}

const getImgKey = (ossUrl:string) => {
  return request.get('/api/feishu/getMessageImageKey?ossUrl='+ossUrl)
}

// '/datacenter/createEvent' post
const sendData = (params)=>{
  return request.post('/datacenter/createEvent', params)
}

export default {
  ...admin,
  ...mobile,
  getStsAssume,
  getUserToken,
  getUserAccessToken,
  getUserInfo,
  getImgKey,
  sendData
}

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { Toast } from 'vant'
import Router from '.././router'
import DCM from '.././util/dcm'
const withoutUrl = ['/api/user/ihaier/check_token','/api/user/userinfo/current','/api/feishu/signature']
const outCancelUrl = ['/api/user/ihaier/check_token','/api/group/front/check_authority']
const request = axios.create({
  timeout: 25000
})
interface Window {
  _axiosPromiseArr: []
}
(window as any)._axiosPromiseArr = []
// 添加请求拦截器
request.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // 在发送请求之前做些什么
    config.headers = {}    
    if (sessionStorage.getItem('staff-token')) {
      config.headers['Authorization'] = sessionStorage.getItem('staff-token')
    }
    const iHaierToken = localStorage.getItem('iHaierToken')
    config.headers['i-haier-token'] = iHaierToken
    if (localStorage.getItem('tenant_id') && withoutUrl.indexOf(config.url.split('?')[0]) === -1) {
      config.headers['X-Tenant-id'] = localStorage.getItem('tenant_id')
    } else {
      config.headers['X-Tenant-id'] = null
    }
    // /api/user/ihaier/check_token
    // if (outCancelUrl.indexOf(config.url.split('?')[0]) === -1) {
    //   config.cancelToken = new axios.CancelToken(cancel => {      
    //     (window as any)._axiosPromiseArr.push({ cancel })
    //   })
    // }

    if(config.url=='/xuehai/h5/v1/user/user_articles_by_chat_user'){
      // console.log('123config:', config)
      config.headers['Authorization'] = 'Bearer '+ iHaierToken
    }
    return config
  },
  function (error: AxiosError) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 添加响应拦截器
request.interceptors.response.use(
  function (response: AxiosResponse) {
    //单独处理埋点请求响应
    const rag = /\/datacenter\/createEvent/;
    if(response && response.request && rag.test(response.request.responseURL || '')){
      console.log('point')
      return response.data
    }
    // 融媒云获取发帖链接情况单独处理
    if(/\/api\/third\/getFontUr/.test(response.request.responseURL)) {
      if(response.status == 200 && response.data.code == '10000'){
        //唯一成功的情况
        return response.data
      }else{
        //从融媒云获取发帖地址报错时，需要跳转至发帖页 NewsCreate
        return Promise.reject(0)
      }
    }
     //单独处理下载
    let filename = ''
    if (response.headers['content-disposition']) {
      filename = response.headers['content-disposition'].split(';')[1].split('filename=')[1]
      console.log(filename);
    }
    const rag1 = /\/export/;
    if(response && response.status == 200 && rag1.test(response.request.responseURL)){
      console.log('export')
      response.data.filename = decodeURIComponent(filename)
      return response.data
    }
    // 对响应数据做点什么
    if (response && response.status == 200 && response.data.code == '10000') {
      return response.data
    }else if(response.data.code == '10024'){
      // 帖子/评论涉及错敏机审不通过时的提示
      Toast(response.data.msg)
      return Promise.resolve({audit: true})
    }
     else {
      // 用于重新发起请求
      const config = response.config
      // 后台选人请求 1000 请求成功  10023 该条评论已被删除 去请求另外一个接口  10018 验证权限  10015 配置管理员没有权限
      if(config.url.includes('xuehai') && response.data.code ==0 ){
        return response.data
      }
      if (response.data.code == '1000' || response.data.code == '10023' || response.data.code == '10015') {
        return response.data
      } else if (response.data.code == '10018') {
        console.log('response.data', response.data);
        return response.data.data
      } else {
        const query = window.location.href.split('?')[1]
        const params = new URLSearchParams(query)
        switch (response.data.code) {
          // 小组不存在
          case 10011:
            if(params.get('accid') || params.get('dismissNavBar')|| params.get('fromihaiermsg')) { // 如果有accid 则是从会话列表跳转进来的  如果有dismissNavBar 是扫描小组二维码进来的
              Router.replace({ name: 'ErrorPage', params: { type: 'group' }, query: { back: '1' } })
            } else { // 如果没有accid 则是从首页跳转的
              Router.replace({ name: 'ErrorPage', params: { type: 'group' } })
            }
            // Toast(response.data.msg)
            break
          // // 配置管理员没有权限
          // case 10015:
          //   Toast(response.data.msg)
          //   Router.go(-1);
          //   break
          // 只剩一个管理员了
          case 10017:
            Toast(response.data.msg)
            break
          // 无权限评论动态
          case 10021:
            Toast(response.data.msg)
            break
          // 动态不存在
          case 10022:
            if(params.get('isMessage')||params.get('fromihaiermsg') || params.get('fromXuehai')) { // 如果有isMessage 则是从消息中心打开新页面进来的
              Router.replace({ name: 'ErrorPage', params: { type: 'news' }, query: { back: '1' } })
            } else { // 如果没有accid 则是从首页跳转的
              Router.replace({ name: 'ErrorPage', params: { type: 'news' } })
            }
            // Toast(response.data.msg)
            break
          // 帖子已删除
          case 30005:
            // 从学海过来的帖子详情
            if(params.get('fromXuehai')){
                Router.replace({ name: 'ErrorPage', params: { type: 'news' }, query: { back: 1 } })
            }else{
                Router.replace({ name: 'ErrorPage', params: { type: 'news' } })
            }
            // Toast(response.data.msg)
            break
          // token已过期，请重新登录
          case 30001:
            console.log('30001  aa', response.data.code);
            // localStorage.clear();
            DCM.getToken().then(
              (res: any) => {
                console.log('DCMTOKEN', res);
                const token = localStorage.getItem('iHaierToken');
                config.headers['i-haier-token'] = token;
                console.log('config', config);
                
                return axios(config)
              },
              (err) => {
                console.log('errerrerr', err);
                throw new Error(err)
              }
            )
            break
          // 置顶动态最多2条，已达上限
          case 30004:
            Toast(response.data.msg)
            break
              // 无权限被禁言
          case 10030:
            Toast(response.data.msg)
            break
            // 创建帖子可能会报这个错
          case 10001:
            Toast(response.data.msg)
            break
          // check_token无效时报这个错
          case 50000:
            // 之前没处理，不知道为什么，现在灭霸一指，全部初始化
            localStorage.clear()
            window.location.reload()
            break;
          case 10019:
            if(params.get('accid') || params.get('dismissNavBar')|| params.get('fromihaiermsg')) { // 如果有accid 则是从会话列表跳转进来的  如果有dismissNavBar 是扫描小组二维码进来的
              Router.replace({ name: 'NoPermission', params: { type: 'group' }, query: { back: '1' } })
            } else { // 如果没有accid 则是从首页跳转的
              Router.replace({ name: 'NoPermission', params: { type: 'group' } })
            }
            window.localStorage.removeItem('groupName')
            break
          default:         
              Toast(response.data.msg)
            break
        }
        return Promise.reject(response.data)
      }
    }
  },
  function (error: AxiosError) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default request
